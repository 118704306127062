@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@450);
@import url(https://fonts.googleapis.com/css2?family=Hanalei&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ballet&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel&family=Cinzel+Decorative&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel&family=Cinzel+Decorative&family=Monsieur+La+Doulaise&display=swap);
/*Add a link to tailwind css framework (Look into customizing this later*/
/*Add letter styling*/
/*Import fonts*/

/*Custom Elments required*/
.ballet {
  font-family: 'Ballet', cursive;
  font-weight: 700;
}
.cinzel {
  font-family: 'Cinzel';
  font-weight: 400;

}

.cindec {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 400;

}


.cursive {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
}
.crimson {
  font-family: "Crimson Pro";
  font-weight: 450;
}
.daughter {
  font-family: "Architects Daughter", cursive;
  font-weight: 400;
}
.hanalei {
  font-family: "Hanalei", cursive;
  font-weight: 700;
}

.monsieur {
  font-family: 'Monsieur La Doulaise', cursive;
  font-weight: 400;
}

.orbitron {
  font-family: "Orbitron", sans-serif;
  font-weight: 400;
}

.raleway {
  font-family: "Raleway";
  font-weight: 300;
}

.gvibes {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}


.home-name {
  font-size: 150px;
}

span.crop {
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: -75%;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  z-index: -1;
}
